const styles = {
    imgArea: {
        height: {
            xs: '50vh',
            sm: '100vh'
        },
        width: '100%'
    },
    img: {
        height: '100%',
        width: '100%',
    },
    gradient: {
        position: 'absolute',
        width: '100%',
        height: '25%',
        left: 0,
        bottom: 0,
        background: `linear-gradient(transparent, rgba(0,0,0,0.2))`
    },
    arrowsArea: {
        position: 'absolute',
        top: '45%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        px: {
            xs: 1,
            sm: 2
        },
        color: '#fff'
    }
}

export default styles