const styles = {
    background: {
        minHeight: {
            md: '100vh',
            xs: '60vh'
        },
        width: '100%',
        paddingTop: '10vh',
        marginTop: {
            md: '8vh',
            xs: '3vh'
        },
        paddingBottom: 5,
        backgroundColor: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sliderArea: {
        width: {
            md: '80vw',
            xs: '90vw'
        },
        marginTop: 5,
        marginBottom: 2
    },
    arrowsArea: {
        display: 'flex',
        alignItems: 'center',
    },
    arrowsText: {
        marginLeft: 1,
        marginRight: 1
    },
    juror: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: .5,
        px: { 
            md: 2,
            xs: 1
        }
    },
    imageArea: {
        width: 110,
        height: 110
    },
    img: {
        height: '100%',
        width: '100%',
    },
    name:{
        marginTop: 1
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10
    }
}

export default styles