import React, { useState, useRef } from 'react'
import styles from './styles'
import Typography from '@mui/material/Typography'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import IconButton from '@mui/material/IconButton'
import JURORS from '@constants/jurors'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from "@mui/material/styles"
import Juror from './Juror'
import EDITIONS from '@constants/editions'

const JurorsList = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [slideIndex, setSlideIndex] = useState(0)
    const sliderRef = useRef(null)

    return (
        <Box sx={ styles.background }>
            <div>
                <Typography gutterBottom color='primary' variant='h3' component='h3' align='center'>
                    Jurados
                </Typography>
                <Typography gutterBottom color='secondary' align='center' sx={{ width: isMobile ? ' 90vw' : '95vh' }}>
                    Conheça os especialistas na arte da fotografia responsáveis pela escolha das obras que melhor representam a sustentabilidade
                </Typography>
            </div>
            <Box sx={ styles.sliderArea }>
                <Slider ref={ sliderRef } infinite speed={ 500 } slidesToShow={ 3 } slidesToScroll={ 3 }
                    afterChange={ index => setSlideIndex(index) } arrows={ false } responsive={[
                        { breakpoint: 1000, settings: { slidesToShow: 2, slidesToScroll: 2 }},
                        { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 }}
                    ]}
                >
                    { JURORS.sort((a, b) => String(a.edition).localeCompare(String(b.edition)) * -1).map(juror => <Juror { ...juror } key={ juror.image }/> )}
                </Slider>
            </Box>
            <div style={ styles.arrowsArea }>
                <IconButton onClick={ () => sliderRef.current.slickPrev() } aria-label='Mostrar jurado anterior'>
                    <ChevronLeftRoundedIcon color='primary'/>
                </IconButton>
                <Typography sx={ styles.arrowsText }>{ `${ EDITIONS[JURORS[slideIndex].edition].index } ª Edição` }</Typography>
                <IconButton onClick={ () => sliderRef.current.slickNext() } aria-label='Mostrar próximo jurado'>
                    <ChevronRightRoundedIcon color='primary' fontSize='inherit'/>
                </IconButton>
            </div>
        </Box>
    )
}

export default JurorsList