import React from "react"
import { Layout } from "@components/organisms"
import { Carousel, JurorsList } from "@pagesComponents/index"
import { BusinessDescription, ImageWithText, PageHeader } from "@components/molecules"
import { IS_SUBSCRIPTION_AVAILABLE } from "@constants/general"
import ROUTES from "@constants/routes"

const Index = () => (
    <Layout transparentAppbar seo={{ title: 'Inicio', description: 'Faça seu clique sobre sustentabilidade'}}> 
        <Carousel/>
        <PageHeader icon={{ name: 'logo_dark', alt: 'Icone Concurso de Fotografia - Foco no Cooperativismo' }}
            button={{ label: 'Participar', path: IS_SUBSCRIPTION_AVAILABLE ? ROUTES.subscription.path : ROUTES.about.path + '#contador' }}
        >
            Faça seu clique sobre sustentabilidade
        </PageHeader>
        <ImageWithText title={ `Por quê sustentabilidade?` } image='winning2019.associate_1'>
            Porque precisamos despertar e provocar o debate sobre o bem-estar social, o desenvolvimento econômico e social, o respeito ao equilíbrio
            dos recursos naturais e sobre a sustentabilidade. Temos a responsabilidade de contribuir de forma significativa com o desenvolvimento de nossa
            comunidade e através da arte da fotografia como ferramenta colaborativa, buscamos conectar a sociedade a esse tema tão importante para a atualidade.
        </ImageWithText>
        <JurorsList/>
        <ImageWithText title='Sobre o concurso' image='winning2018.professional_3' reverse sx={{ my: 6 }}>
            O Foco no Cooperativismo é um concurso de caráter cultural que visa valorizar a arte da fotografia, fomentando a cultura e a responsabilidade
            socioambiental e sustentável e incentivando a participação da comunidade em ações colaborativas. Destacamos talentos, divulgamos belezas
            naturais, provocando o debate socioambiental e despertamos para a consciência sustentável. O resultado final é uma
            exposição itinerante com as obras vencedoras em todas as agências do Sicoob Credisulca.
        </ImageWithText>
        <BusinessDescription/>
    </Layout>
)

export default Index
