import React from 'react'
import styles from './styles'
import Typography from '@mui/material/Typography'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useFolderImages from '@hooks/useFolderImages'
import Box from '@mui/material/Box'
import ROUTES from '@constants/routes'
import { ButtonLink } from '@components/atoms'
import EDITIONS from '@constants/editions'

const Juror = props => {
    const { name, description, image, edition } = props
    const jurorsImages = useFolderImages('jurors')
    const subDescription = description.substr(0, description.slice(0, 100).lastIndexOf(" "))
    const imageData = jurorsImages.find(el => el.name === image)
    
    return (
        <Box key={ image } sx={ styles.juror } itemScope itemType="http://schema.org/Person">
            <Box sx={ styles.imageArea }>
                <GatsbyImage image={ getImage(imageData) } alt={ `Jurado(a) ${ name }` } style={ styles.img }/>
            </Box>
            <Typography color='primary' variant='h6' align='center' sx={ styles.name } itemProp='name'>
                { name }
            </Typography>
            <Typography color='primary' variant='subtitle2' align='center' gutterBottom>
                { EDITIONS[edition].index }ª Edição
            </Typography>
            <Typography color='secondary' variant='body2' align='center' itemProp='description'>
                { subDescription }{ description.length > subDescription.length ? '...' : ''}
            </Typography>
            <div style={ styles.button } >
                <ButtonLink to={ ROUTES.jurors.path + `#${ image.split('.')[0] }` } size='small' state={{ edition }} variant='outlined'>
                    Saiba Mais
                </ButtonLink>
            </div>
        </Box> 
    )
}

export default Juror