import React, { useRef, useState } from 'react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import styles from './styles'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import WINNING_PHOTOS from '@constants/winningPhotos'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PhotoTitle } from '@components/molecules'
import useFolderImages from '@hooks/useFolderImages'
import { CAROUSEL_PHOTOS_FOLDER } from '@constants/general'

const Carousel = () => {
    const listOfImages = useFolderImages(CAROUSEL_PHOTOS_FOLDER)
    const sliderRef = useRef(null)
    const [activeItem, setActiveItem] = useState(0)
    const [infosVisible, setInfosVisible] = useState(true)
    const { position, author, title } = WINNING_PHOTOS[2021].professional[activeItem]

    const handleAfterChange = index => {
        setActiveItem(index)
        setInfosVisible(true)
    }

    const handleBeforeChange = () => {
        setInfosVisible(false)
    }

    return (
        <div style={{ position: 'relative', marginBottom: 30 }} itemScope itemType='https://schema.org/ImageGallery'>
            <Slider infinite autoplaySpeed={ 6000 } autoplay dots={ false } speed={ 700 } arrows={ false }
                swipeToSlide pauseOnDotsHover ref={ sliderRef } beforeChange={ handleBeforeChange } afterChange={ handleAfterChange }
            >
                { WINNING_PHOTOS[2021].professional.map(({ position, title }) => {
                    const image = listOfImages.find(({ name }) => name === `professional_${ position }`)

                    return (
                        <Box sx={ styles.imgArea } key={ position } >
                            <GatsbyImage image={ getImage(image) } alt={ title } loading={ position === 0 ? 'eager'  : 'auto' } style={ styles.img }/>
                            <div style={ styles.gradient }/>
                        </Box> 
                    )
                })}
            </Slider>
            <Box sx={ styles.arrowsArea }>
                <IconButton onClick={ () => sliderRef.current.slickPrev() } color='inherit' aria-label='Mostrar foto anterior'>
                    <ChevronLeftRoundedIcon fontSize='large'/>
                </IconButton>
                <IconButton onClick={ () => sliderRef.current.slickNext() } color='inherit' aria-label='Mostrar próxima foto'>
                    <ChevronRightRoundedIcon fontSize='large'/>
                </IconButton>
            </Box>
            <PhotoTitle visible={ infosVisible } title={ title } author={ author } position={ position }/>
        </div>
    )
}

export default Carousel